import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer2 = styled.div`
  width: 50%;
  height: 800px;
`;

export const Image2 = styled.img`
  width: 800%;
  height: 500%;
  border-radius: 17px 17px 17px 17px;
  background-image: url(${(props) => props.url});
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ImageRwd = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  width: 90vw;

  @media (min-width: 640px) {
      background-size: contain;
      width: 100vw;
  }
`;

export const ImageContainer = styled.div`

`;

export const Image = styled.div`
  width: 200px;
  height:200px;
  border: 2px solid;
  border-radius: 14px;
  transform: rotate(${(props) => (props.pick ? "-2deg" : "0")});
  border-color: ${(props) => (props.pick ? "#3AB795" : "black")};
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat:no-repeat;
  background-position:center;

  @media (max-width: 950px) {
    width: 100px; /* 或其他適合小螢幕的寬度 */
    height: 130px; /* 讓高度自動調整 */
  }

`;

export const Feedback = styled.div`
  position: absolute;
  top: 20%;
  z-index: 2;
  visibility: ${(props) => (props.send ? "block" : "hidden")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
`;

export const ConfirmBtn = styled.div`
  width: 100%;
  height: 80%;
  padding:3px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  background: #ff7663;
  border: 1.5px solid #3b3b3b;
  border-radius: 38px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  margin-bottom: 20px;
  visibility: ${(props) => (props.click ? "block" : "hidden")};

  @media (max-width: 950px) {
    width:100%;
    height:50%;
    font-size:18px;
  }
`;

export const SpeakingBtn = styled.div`
  display: flex;
  align-items: center;
  padding:3px 0px;
  justify-content: center;
  width: 100%;
  height: 80%;
  background: ${(props) => props.BtnColor};
  border: 1.5px solid #3b3b3b;
  border-radius: 38px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  margin-bottom: 20px;
  cursor: pointer;

  @media (max-width: 950px) {
    width:100%;
    height:50%;
    font-size:28px;
  }
`;

export const BtnContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ContinueBtn = styled.div`
  visibility: ${(props) => (props.correct && props.send ? "block" : "hidden")};
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
  cursor: pointer;
`;

export const GoBackBtn = styled.div`
  visibility: ${(props) => (props.speaking ? "hidden" : "block")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 47px;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
`;

export const ButtonIcon = styled.img`
  margin: 0px 6px;
  width: ${(props) => props.width};
  src: url(${(props) => props.src});

  @media (max-width: 900px) {
    width:15%;
  }
`;

export const FullScreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // 確保模態在最上層
`;

export const GifAnimation = styled.img`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.url});
  object-fit: cover;
`;

export const StageGroup = styled.div`
  margin-top: -10px;
  margin-bottom: 10px;
  display:flex;

  @media (max-width: 950px) {
    margin-top: 0px;
  }
`;

export const Stagetext = styled.div`
  margin:0px 10px;
  font-size:20px;
  letter-spacing:5px;
`;

export const Stagebtn = styled.div`
  font-size:20px;
  cursor:pointer;
  margin-top:4px;
`;
