import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
  display: flex;
  align-items: center;
  flex-direction: column;
`;


export const ImageContainer = styled.div`
  width:800px;
  height:500px;
  overflow: hidden;
  position: relative;
  margin-top: 0px;
`;

export const Image = styled.img`
  position: absolute;
  width: 800%;
  height: 500%;
  border-radius: 17px 17px 17px 17px;
  background-image: url(${(props) => props.url});
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;


// export const BtnContainer = styled.div`

// `;

export const SpeakingBtn = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.BtnColor};
  border: 1.5px solid #3b3b3b;
  border-radius: 38px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
  cursor: pointer;
`;

export const ButtonIcon = styled.img`
  margin: 0px 6px;
  width: ${(props) => props.width};
  src: url(${(props) => props.src});
`;

export const ContinueBtn = styled.div`
  display: ${(props) => (!props.isShow ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
  cursor: pointer;
`;

export const GoBackBtn = styled.div`
  display: ${(props) => (props.speaking ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 47px;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
`;
