import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import close from "../../public/images/close.png";
import settings from "../../public/images/settings.png";
import check_circle from "../images/check_circle.png";
import {
  ButtonGroup,
  CancelButton,
  CloseButton,
  Innertitle,
  Nav,
  NavBtn,
  NavIcon,
  Overlay,
  RadioButton,
  RadioGroup,
  RadioLabel,
  RangeInput,
  RangeInputWrapper,
  SaveButton,
  Setting,
  SpeedDisplay,
  Title
} from "./style";

const NavBar = ({index1,index2}) => {
  const [settingclick, setsettingclick] = useState(false);
  const navigate = useNavigate();
  const [voice, setVoice] = useState('female'); // 預設為女聲
  const [speed, setSpeed] = useState("1"); // 初始語速設為 0.75
  const [showSettings, setShowSettings] = useState(false);
  const [picstate, setPicstate] = useState("o");


  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let voice1 = searchParams.get("voice");
  let speed1 = parseFloat(searchParams.get("speed"));
  let level = searchParams.get("level");
  let stage1 = searchParams.get("stage1");
  let education = (
    searchParams.get("education") !== null &&
    searchParams.get("education") !== undefined
  ) ? searchParams.get("education") : '';
  let educationQueryString = (
    searchParams.get("education") !== null &&
    searchParams.get("education") !== undefined
  ) ? `?education=${education}` : '';

  // 處理滑動條變化的函數
  const handleSpeedChange = (e) => {
    let newSpeed = parseFloat(e.target.value);
    newSpeed = Math.max(newSpeed, 0.1); // 確保語速不會低於0.25倍
    setSpeed(newSpeed);
  }
  const Settingbtnclick = () => {
    setsettingclick(!settingclick);
    setShowSettings(!showSettings);
    setVoice(voice1);
    setSpeed(speed1);
  }
  
  const whenSave = () => {

    console.log("the index2 is "+ index2)

    if(index2 == 1 || index2 == 7 || index2 == 13 || index2 == 19 || index2 == 25 || index2 == 31 || index2 == 37 || index2 == 43 || index2 == 49 || index2 == 55 || index2 == 61 || index2 == 67){
      window.location.href = `/listen${educationQueryString}&level=${level}&index1=${parseInt(index1)}&index2=${parseInt(index2)}&speed=${speed}&voice=${voice}`;
    }else {
      window.location.href = `/question${educationQueryString}&level=${level}&index1=${parseInt(index1)}&index2=${parseInt(index2)}&speed=${speed}&voice=${voice}&stage1=${stage1}`;
    }
  }


  return (
    <Nav>
      <NavBtn onClick={Settingbtnclick}>
        <NavIcon width={24} src={settings} />
        設定
      </NavBtn>
      <Overlay show={showSettings} onClick={Settingbtnclick} />
      {settingclick && (
        <Setting>
          <CloseButton onClick={() => Settingbtnclick()}>X</CloseButton>
          <Title>語音調整</Title>
          <RadioGroup>
            <Innertitle>聲音</Innertitle>
            <RadioLabel>
              <RadioButton 
                type="radio" 
                name="voice" 
                value="female" 
                checked={voice === 'female'}
                onChange={(e) => setVoice(e.target.value)}
              />
              女聲1
            </RadioLabel>
            <RadioLabel>
              <RadioButton 
                type="radio" 
                name="voice" 
                value="female2" 
                checked={voice === 'female2'}
                onChange={(e) => setVoice(e.target.value)}
              />
              女聲2
            </RadioLabel>
            <RadioLabel>
              <RadioButton 
                type="radio" 
                name="voice" 
                value="male" 
                checked={voice === 'male'}
                onChange={(e) => setVoice(e.target.value)}
              />
              男聲
            </RadioLabel>
          </RadioGroup>
          <RangeInputWrapper>
            <Innertitle>倍速</Innertitle>
            <RangeInput
              type="range"
              min="0.1"
              max="2"
              step="0.1"
              value={speed}
              onChange={handleSpeedChange}
            />
            <SpeedDisplay>{speed}倍速</SpeedDisplay>
          </RangeInputWrapper>
          {/*<Picgroup>
            <Innertitle>圖片設定</Innertitle>
            <RadioLabel>
              <RadioButton 
                type="radio" 
                name="picstate" 
                value="o" 
                checked={picstate === 'o'}
                onChange={(e) => setPicstate(e.target.value)}
              />
              開啟圖片
            </RadioLabel>
            <RadioLabel>
              <RadioButton 
                type="radio" 
                name="picstate" 
                value="c" 
                checked={picstate === 'c'}
                onChange={(e) => setPicstate(e.target.value)}
              />
              關閉圖片
            </RadioLabel>
      </Picgroup>*/}

          <ButtonGroup>
            <SaveButton onClick={() => {whenSave() }}>
              <img src={check_circle} width={16} style={{marginRight:"15px",}} alt="Save" />確定
            </SaveButton>
            <CancelButton onClick={() => Settingbtnclick()}>
              <img src={close} width={16} style={{marginRight:"15px",}} alt="Cancel" />取消
            </CancelButton>
          </ButtonGroup>            
        </Setting>
      )}
      <NavBtn onClick={() => { window.location.href = `/${educationQueryString}`;}}>
        <NavIcon width={20} src={close} />
        離開
      </NavBtn>
        {/*
      <Folder>
        <NavIcon width={24} src={triangle} />
      </Folder>
        */ }
    </Nav>
    
  );
};

export default NavBar;
