import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";
import Report from "../../components/Report/Report";
import level1 from "../../public/images/level1.png";
import level2 from "../../public/images/level2.png";
import level3 from "../../public/images/level3.png";
import userpic from "../../public/images/user.png";
import {
  BtnContainer,
  BtnView,
  ButtonIcon,
  Container,
  Title
} from "./style.js";

const Level = () => {
  const [user, setUser] = useState(null);
  const [showSignOut, setShowSignOut] = useState(false);
  const navigate = useNavigate();
  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let education = (
    searchParams.get("education") !== null &&
    searchParams.get("education") !== undefined
  ) ? searchParams.get("education") : '';
  let educationQueryString = (
    searchParams.get("education") !== null &&
    searchParams.get("education") !== undefined
  ) ? `&education=${education}` : '';
  
  //是否有登入
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 使用者已登入，更新狀態
        setUser(user);
        // console.log(user);
      } else {
        if (education == 1) {

        } else {
          // 使用者未登入，將狀態設為null並重定向到登入頁面
          // 假設你的登入頁面路徑是'/login'
          setUser(null);
          navigate('/Login'); 
        }
      }
    });
  }, [navigate]);

  // 登出函數
  const handleSignOut = () => {
    signOut(auth).then(() => {
      alert('登出成功！');
      navigate('/Login');
    }).catch((error) => {
      // 處理錯誤
      console.error("登出錯誤", error);
    });
  };

  return (
    <ModalProvider>
      <Helmet>
        <title>首頁 - 層級選擇</title>
      </Helmet>
      <div style={{position:'fixed', right:'15px', top:'15px'}}>
          {user && (
            <span>
              <img src={userpic} alt='使用者' onClick={() => setShowSignOut(!showSignOut)} style={{width:'35px', height:'35px',cursor: 'pointer'}}/>
              {showSignOut && (
                <div style={{ position:'fixed', right:'1%',marginTop:'20px', backgroundColor:'#e5e5e5', padding:'1% 2%', borderRadius:'10px'}}>
                  <h4 style={{marginBottom:'10px'}}>歡迎：{user.email}登入使用</h4>
                  <button style={{padding:'2px 123px',cursor:'pointer'}} onClick={handleSignOut}>登出</button>
                </div>
              )}
            </span>
          )}
      </div>
      <Container>
        <Title>你今天想學點什麼呢？</Title>
        <BtnContainer>
          <div className="relative grid grid-cols-12 gap-8">
            <div className="col-span-12 lg:col-span-4 flex justify-center cursor-pointer">
              <BtnView
                color={"#FDA90C"}
                onClick={() => navigate(`/home?level=${1}${educationQueryString}`)}
              >
                <ButtonIcon src={level1} width={40}></ButtonIcon>
                初級
              </BtnView>
            </div>
            <div className="col-span-12 lg:col-span-4 flex justify-center cursor-pointer">
              <BtnView
                color={"#33AB83"}
                onClick={() => navigate(`/home?level=${2}${educationQueryString}`)}
              >
                <ButtonIcon src={level2} width={40}></ButtonIcon>
                中級
              </BtnView>
            </div>
            <div className="col-span-12 lg:col-span-4 flex justify-center cursor-pointer">
              <BtnView
                color={"#FD5E51"}
                onClick={() => navigate(`/home?level=${3}${educationQueryString}`)}
              >
                <ButtonIcon src={level3} width={40}></ButtonIcon>
                中高級
              </BtnView>
            </div>
          </div>
        </BtnContainer>
      </Container>
      <Report />
    </ModalProvider>
  );
};

export default Level;
